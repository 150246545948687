import { Box, Button, Heading, useBreakpointValue } from '@chakra-ui/react';
import { RoutePath } from 'router/routes.paths';
import BPCPageHeading from 'common/components/BPCPageHeading';
import RangeList from 'features/ranges/components/RangeList';
import { FaPlus } from 'react-icons/fa';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  createPokerRange,
  useGetUserPokerRanges,
} from 'features/ranges/ranges.api';
import Loader from 'common/components/Loader';
import FilterRangesForm, {
  RangeFilter,
} from 'features/ranges/components/FilterRangesForm';
import { PokerRangeFirebase, Seat } from 'features/ranges/ranges.type';
import { useEffect, useMemo, useState } from 'react';
import { useConnectedUserContext } from 'features/user/useConnectedUserContext';
import calamusaRanges from 'features/ranges/constants/ranges-calamusa.json';
import { getValidSeatsForTableType } from 'features/ranges/ranges.helper';
import RangeCompare from 'features/ranges/components/RangeCompare';

function filterRanges(
  rangesToFilter: PokerRangeFirebase[],
  rangesFilter: RangeFilter,
) {
  let filteredRanges = rangesToFilter;
  if (rangesFilter.category) {
    filteredRanges = filteredRanges.filter(
      (range) => range.category === rangesFilter.category,
    );
  }
  if (rangesFilter.position) {
    filteredRanges = filteredRanges
      .map((range) => ({
        ...range,
        positions: range.positions.filter(
          (position) => position.table === rangesFilter.position?.table,
        ),
      }))
      .filter((range) =>
        range.positions.some(
          (position) =>
            position.table === rangesFilter.position!.table &&
            (rangesFilter.position!.seat
              ? position.seats.includes(rangesFilter.position!.seat as Seat)
              : true),
        ),
      );
  }
  if (rangesFilter.stack) {
    filteredRanges = filteredRanges.filter(
      (range) =>
        (range.stack?.min || 0) <= rangesFilter.stack! &&
        (range.stack?.max ? range.stack?.max >= rangesFilter.stack! : true),
    );
  }
  return filteredRanges;
}

function sortRanges(rangesToSort: PokerRangeFirebase[]) {
  return [...rangesToSort].sort((a, b) => {
    //Sort by category
    if (a.category !== b.category) {
      return a.category.localeCompare(b.category);
    }

    //Sort by position
    if (a.positions.length && b.positions.length) {
      const biggestPositionA = a.positions.reduce(
        (acc, position) =>
          acc.table && position.table && acc.table > position.table
            ? acc
            : position,
        a.positions[0],
      );
      const rankBiggestPositionA = getValidSeatsForTableType(
        biggestPositionA.table,
      ).findIndex((seat) => biggestPositionA.seats.includes(seat));

      const biggestPositionB = b.positions.reduce(
        (acc, position) =>
          acc.table && position.table && acc.table > position.table
            ? acc
            : position,
        a.positions[0],
      );
      const rankBiggestPositionB = getValidSeatsForTableType(
        biggestPositionB.table,
      ).findIndex((seat) => biggestPositionB.seats.includes(seat));

      if (
        biggestPositionA.table === biggestPositionB.table &&
        rankBiggestPositionA !== rankBiggestPositionB
      ) {
        return rankBiggestPositionA - rankBiggestPositionB;
      }
    }

    //Sort by stack size
    return a.stack && b.stack ? a.stack.min - b.stack.min : 0;
  });
}

export default function OutilRangesPage() {
  const { user } = useConnectedUserContext();
  const navigate = useNavigate();
  const createTitle = useBreakpointValue(
    { base: '', md: 'Créer' },
    { fallback: 'md' },
  );

  const [isImportLoading, setIsImportLoading] = useState(false);
  const { ranges, isLoading } = useGetUserPokerRanges();
  const [rangesFilter, setRangesFilter] = useState<RangeFilter>({});
  const [rangesToDisplay, setRangesToDisplay] = useState(ranges);
  const { search } = useLocation();

  const isCompareActive = useMemo(
    () =>
      Boolean(new URLSearchParams(search).get('isCompareActive') === 'true'),
    [search],
  );

  useEffect(() => {
    setRangesToDisplay(filterRanges(sortRanges(ranges), rangesFilter));
  }, [ranges, rangesFilter]);

  async function loadCalamusaRanges() {
    if (!user) {
      return;
    }
    setIsImportLoading(true);
    await Promise.all(
      calamusaRanges.map((range) =>
        createPokerRange(range as PokerRangeFirebase, user.id),
      ),
    );
    setIsImportLoading(false);
  }

  if (isLoading || isImportLoading) {
    return <Loader fullScreen />;
  }

  return (
    <Box>
      <BPCPageHeading
        helmetName="Ranges"
        title="Révision des ranges"
        breadcrumbItems={[
          { to: RoutePath.home, name: 'Home' },
          { to: RoutePath.outils, name: 'Outils' },
        ]}
        buttonProps={[
          {
            title: isCompareActive ? 'Vue liste' : 'Comparer',
            onClick: () =>
              navigate(
                `${RoutePath.ranges}?isCompareActive=${!isCompareActive}`,
              ),
            variant: 'outline',
          },
          {
            leftIcon: <FaPlus />,
            onClick: () => navigate(`${RoutePath.ranges}/creation`),
            title: createTitle,
          },
        ]}
      />
      <Box mb={4}>
        <Heading size={'m'} mb={2}>
          Filtrer
        </Heading>
        <FilterRangesForm onFilterChange={setRangesFilter} />
      </Box>
      {ranges.length ? (
        isCompareActive ? (
          <RangeCompare
            key={JSON.stringify(rangesToDisplay)}
            ranges={rangesToDisplay}
          />
        ) : (
          <RangeList ranges={rangesToDisplay} />
        )
      ) : (
        <Button onClick={loadCalamusaRanges}>
          Charger les ranges d'open de Calamusa
        </Button>
      )}
    </Box>
  );
}
