import { useMemo, useState } from 'react';
import {
  PokerRange,
  RangeType,
  rangeTypes,
  rangeCategories,
} from '../ranges.type';
import {
  Alert,
  AlertIcon,
  AlertDescription,
  Box,
  Button,
  ButtonGroup,
  Flex,
  Radio,
  RadioGroup,
  SimpleGrid,
  Text,
  VStack,
  Checkbox,
  CheckboxGroup,
  FormControl,
  FormLabel,
  Icon,
  IconButton,
  Stack,
  HStack,
} from '@chakra-ui/react';
import {
  rangeTypeColors,
  rangeTypeNames,
  seatNames,
} from '../ranges.constants';
import { BPCYup } from 'common/form.validation';
import { ArrayHelpers, FieldArray, Formik, getIn } from 'formik';
import { createPokerRange, updatePokerRange } from '../ranges.api';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from 'router/routes.paths';
import BPCHandMatrixEditable from './BPCHandMatrix/BPCHandMatrixEditable';
import {
  computeRangePercentageForNbCombos,
  computeTotalCombosForRange,
  formatRangeTableLabel,
  getValidSeatsForTableType,
} from '../ranges.helper';
import { BPCInput } from 'common/components/form/BPCInput';
import { FaPlus, FaTrash } from 'react-icons/fa';

const NO_RANGE_TYPE = 'noRangeType';

const DEFAULT_RANGE: Omit<PokerRange, 'id'> = {
  name: '',
  category: 'Open',
  positions: [{ seats: [] }],
  details: {},
};

const RangeSchema = BPCYup.object().shape({
  name: BPCYup.string().min(3, 'Le nom doit contenir au moins 3 caractères'),
  category: BPCYup.string().required(),
  positions: BPCYup.array(
    BPCYup.object().shape({
      table: BPCYup.number().integer().min(2).max(10),
      seats: BPCYup.array(BPCYup.string()).min(1),
      versus: BPCYup.array(BPCYup.string()),
    }),
  ).min(1),
  stack: BPCYup.object().shape({
    min: BPCYup.number().integer().min(1).required(),
    max: BPCYup.number().integer().min(1),
  }),
  note: BPCYup.string(),
  details: BPCYup.object().required(),
});

export default function EditRangeForm({
  range,
  userId,
}: Readonly<{
  range?: PokerRange;
  userId: string;
}>) {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const navigate = useNavigate();

  const initialValues = useMemo(() => range ?? DEFAULT_RANGE, [range]);
  const [selectedRangeType, setSelectedRangeType] = useState<
    RangeType | undefined
  >('openFold');

  return (
    <Box>
      <Formik
        initialValues={initialValues}
        validationSchema={RangeSchema}
        onSubmit={async (rangeToSave) => {
          setErrorMessage(undefined);
          setIsLoading(true);
          try {
            if (range?.id) {
              await updatePokerRange(
                {
                  id: range.id,
                  ...rangeToSave,
                },
                userId,
              );
            } else {
              await createPokerRange(rangeToSave, userId);
            }
            navigate(RoutePath.ranges);
          } catch (e: any) {
            const { code, message } = e;
            console.error(
              `Erreur lors de ${
                range ? "l'édition" : 'la création'
              } de la range:${code} message:${message}`,
            );
            setErrorMessage(message);
          }
          setIsLoading(false);
        }}
        enableReinitialize
      >
        {({ handleSubmit, setFieldValue, values, errors, touched }) => {
          const nbCombosPerType = computeTotalCombosForRange(values.details);
          const hasVersusSeat = values.category === 'Call/3bet';
          return (
            <form onSubmit={handleSubmit}>
              <VStack spacing={3} alignItems={'flex-start'} width={'100%'}>
                {errorMessage ? (
                  <Alert status="error">
                    <AlertIcon />
                    <AlertDescription>{errorMessage}</AlertDescription>
                  </Alert>
                ) : undefined}

                <BPCInput fieldName="name" label="Nom" type="text" />

                {/* Category */}
                <FormControl
                  isInvalid={Boolean(!!errors.category && touched.category)}
                >
                  <FormLabel>Categorie de range</FormLabel>
                  <RadioGroup
                    colorScheme="orange"
                    value={values.category}
                    onChange={(val) => {
                      setFieldValue(`category`, val);
                      if (
                        val === 'Open' &&
                        values.positions.some(
                          (position) => position.versus?.length,
                        )
                      ) {
                        setFieldValue(
                          'positions',
                          values.positions.map((p) => ({
                            ...p,
                            versus: undefined,
                          })),
                        );
                      }
                    }}
                    mb={2}
                  >
                    <SimpleGrid columns={3} spacingX={3}>
                      {rangeCategories.map((category) => (
                        <Radio key={category} value={category}>
                          {category}
                        </Radio>
                      ))}
                    </SimpleGrid>
                  </RadioGroup>
                </FormControl>

                {/* Positions */}
                <FormControl
                  isInvalid={Boolean(!!errors.positions && touched.positions)}
                >
                  <FormLabel>
                    <Flex alignItems={'center'}>
                      Positions et types de tables
                      <IconButton
                        size={'xs'}
                        ml={3}
                        aria-label=""
                        icon={<FaPlus />}
                        onClick={() =>
                          setFieldValue('positions', [
                            ...values.positions,
                            values.positions.length
                              ? values.positions[values.positions.length - 1]
                              : DEFAULT_RANGE.positions[0],
                          ])
                        }
                      />
                    </Flex>
                  </FormLabel>

                  <FieldArray
                    name="positions"
                    render={(arrayHelpers: ArrayHelpers) => (
                      <VStack
                        spacing={3}
                        alignItems={'flex-start'}
                        width={'100%'}
                      >
                        {typeof errors.positions === 'string' ? (
                          <Box color="red.500">{errors.positions}</Box>
                        ) : null}
                        {values.positions.map(({ table, seats, versus }, i) => {
                          const positionErrorMessage = getIn(
                            errors,
                            `positions[${i}].seats`,
                          );
                          const validSeats = getValidSeatsForTableType(table);
                          return (
                            <Flex
                              alignItems={'center'}
                              justifyContent={'space-between'}
                              key={i}
                              width={'100%'}
                              border={
                                values.positions.length > 1
                                  ? '1px solid orange'
                                  : undefined
                              }
                              p={2}
                            >
                              <HStack width="full">
                                <VStack width="full" alignItems={'flex-start'}>
                                  <RadioGroup
                                    colorScheme="orange"
                                    value={`${table}`}
                                    onChange={(val) =>
                                      setFieldValue(`positions[${i}]`, {
                                        table: +val,

                                        seats: seats.filter((seat) =>
                                          validSeats.includes(seat),
                                        ),
                                      })
                                    }
                                    mb={2}
                                  >
                                    <SimpleGrid
                                      columns={{ base: 3, md: 6 }}
                                      spacingX={3}
                                    >
                                      {[10, 8, 6, 4, 3, 2].map((nbSeat) => (
                                        <Radio key={nbSeat} value={`${nbSeat}`}>
                                          {formatRangeTableLabel(nbSeat)}
                                        </Radio>
                                      ))}
                                    </SimpleGrid>
                                  </RadioGroup>

                                  <SimpleGrid
                                    columns={hasVersusSeat ? 2 : 1}
                                    width="full"
                                    templateColumns={
                                      hasVersusSeat ? '55px auto' : undefined
                                    }
                                    spacingX={3}
                                  >
                                    {hasVersusSeat && <Text>Siège</Text>}
                                    <Box>
                                      <CheckboxGroup
                                        colorScheme="orange"
                                        value={seats}
                                        onChange={(val) =>
                                          setFieldValue(
                                            `positions[${i}].seats`,
                                            val,
                                          )
                                        }
                                      >
                                        <SimpleGrid
                                          columns={{ base: 3, md: 6 }}
                                          spacingX={3}
                                        >
                                          {validSeats.map((seat) => (
                                            <Checkbox key={seat} value={seat}>
                                              {seatNames[seat]}
                                            </Checkbox>
                                          ))}
                                        </SimpleGrid>
                                      </CheckboxGroup>
                                      {positionErrorMessage && (
                                        <Text color="red.500">
                                          {positionErrorMessage}
                                        </Text>
                                      )}
                                    </Box>
                                    {hasVersusSeat && (
                                      <>
                                        <Text>Contre</Text>
                                        <CheckboxGroup
                                          colorScheme="orange"
                                          value={versus}
                                          onChange={(val) =>
                                            setFieldValue(
                                              `positions[${i}].versus`,
                                              val,
                                            )
                                          }
                                        >
                                          <SimpleGrid
                                            columns={{ base: 3, md: 6 }}
                                            spacingX={3}
                                          >
                                            {validSeats.map((seat) => (
                                              <Checkbox
                                                key={seat}
                                                value={seat}
                                                disabled={seats.includes(seat)}
                                              >
                                                {seatNames[seat]}
                                              </Checkbox>
                                            ))}
                                          </SimpleGrid>
                                        </CheckboxGroup>
                                      </>
                                    )}
                                  </SimpleGrid>
                                </VStack>
                                <Box>
                                  <Button
                                    type="button"
                                    onClick={() => arrayHelpers.remove(i)}
                                    size="xs"
                                  >
                                    <Icon as={FaTrash} />
                                  </Button>
                                </Box>
                              </HStack>
                            </Flex>
                          );
                        })}
                      </VStack>
                    )}
                  />
                </FormControl>

                {/* Stacks */}
                <FormControl>
                  <FormLabel>Taille du tapis</FormLabel>
                  <SimpleGrid columns={2} spacingX={3}>
                    <BPCInput
                      fieldName="stack.min"
                      label="Min"
                      type="number"
                      suffix="bb"
                      isRequired
                    />
                    <BPCInput
                      fieldName="stack.max"
                      label="Max"
                      type="number"
                      suffix="bb"
                    />
                  </SimpleGrid>
                </FormControl>

                {/* Details */}
                <FormControl>
                  <FormLabel>Détails</FormLabel>
                  <Text mb={3}>
                    Total :{' '}
                    {computeRangePercentageForNbCombos(nbCombosPerType.total)}
                  </Text>
                  <Stack
                    width="100%"
                    direction={{ base: 'column-reverse', md: 'row' }}
                    spacing={3}
                  >
                    <BPCHandMatrixEditable
                      rangeDetails={values.details}
                      selectedRangeType={selectedRangeType}
                      onChange={(newDetails) =>
                        setFieldValue('details', newDetails)
                      }
                      style={{ width: '100%' }}
                    />
                    <RadioGroup
                      width={'100%'}
                      value={selectedRangeType}
                      colorScheme="orange"
                      onChange={(newType: RangeType | typeof NO_RANGE_TYPE) =>
                        setSelectedRangeType(
                          newType === NO_RANGE_TYPE ? undefined : newType,
                        )
                      }
                      mb={3}
                    >
                      <VStack alignItems={'flex-start'} spacing={2}>
                        <Radio value={NO_RANGE_TYPE}>
                          <Text>Aucune</Text>
                        </Radio>
                        {rangeTypes.map((type) => {
                          const rangePercentage =
                            computeRangePercentageForNbCombos(
                              nbCombosPerType[type],
                            );
                          return (
                            <Radio key={type} value={type}>
                              <Flex alignItems={'center'}>
                                <Box
                                  bg={rangeTypeColors[type]}
                                  h="1rem"
                                  w="1rem"
                                  mr={2}
                                />
                                <Text>{`${rangeTypeNames[type]}${
                                  rangePercentage ? ` (${rangePercentage})` : ''
                                }`}</Text>
                              </Flex>
                            </Radio>
                          );
                        })}
                      </VStack>
                    </RadioGroup>
                  </Stack>
                </FormControl>

                <BPCInput
                  fieldName="note"
                  label="Note (ex: sizing d'open)"
                  type="text"
                />

                <ButtonGroup gap={4} ml="auto">
                  <Button
                    width="full"
                    onClick={() => navigate(-1)}
                    variant={'outline'}
                  >
                    Annuler
                  </Button>

                  <Button isLoading={isLoading} type="submit" width="full">
                    Valider
                  </Button>
                </ButtonGroup>
              </VStack>
            </form>
          );
        }}
      </Formik>
    </Box>
  );
}
