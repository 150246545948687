import { Seat, rangeCategories } from '../ranges.type';
import {
  Box,
  Flex,
  Radio,
  RadioGroup,
  SimpleGrid,
  VStack,
  FormControl,
  FormLabel,
  Checkbox,
} from '@chakra-ui/react';
import { seatNames } from '../ranges.constants';
import { BPCYup } from 'common/form.validation';
import { Formik } from 'formik';
import { getValidSeatsForTableType } from '../ranges.helper';
import { BPCInput } from 'common/components/form/BPCInput';
import { useState } from 'react';

const RangeFilterSchema = BPCYup.object().shape({
  category: BPCYup.string(),
  position: BPCYup.object()
    .shape({
      table: BPCYup.number().integer().min(2).max(10),
      seat: BPCYup.string(),
    })
    .optional(),
  stack: BPCYup.number().integer().min(1).optional(),
});

export type RangeFilter = BPCYup.InferType<typeof RangeFilterSchema>;

export default function FilterRangesForm({
  onFilterChange,
}: Readonly<{
  onFilterChange: (values: RangeFilter) => void;
}>) {
  const [isFilterOnCategory, setIsFilterOnCategory] = useState(false);
  const [isFilterOnPosition, setIsFilterOnPosition] = useState(false);
  const [isFilterOnStack, setIsFilterOnStack] = useState(false);
  return (
    <Box>
      <Formik
        initialValues={{}}
        validationSchema={RangeFilterSchema}
        onSubmit={onFilterChange}
      >
        {({ handleSubmit, setFieldValue, values, errors, touched }) => {
          return (
            <form onSubmit={(e) => e.preventDefault()} onChange={handleSubmit}>
              <VStack spacing={3} alignItems={'flex-start'} width={'100%'}>
                {/* Category */}

                <FormControl
                  display={'flex'}
                  alignItems={'center'}
                  isInvalid={Boolean(!!errors.category && touched.category)}
                >
                  <Checkbox
                    checked={isFilterOnCategory}
                    onChange={(e) => {
                      const isChecked = e.target.checked;
                      setIsFilterOnCategory(isChecked);
                      if (!isChecked) {
                        setFieldValue('category', undefined);
                      }
                    }}
                  >
                    <FormLabel m={0} mr={2}>
                      Categorie
                    </FormLabel>
                  </Checkbox>
                  {isFilterOnCategory && (
                    <RadioGroup
                      m={0}
                      colorScheme="orange"
                      value={values.category}
                      onChange={(val) => setFieldValue(`category`, val)}
                    >
                      <SimpleGrid columns={3} spacingX={3}>
                        {rangeCategories.map((category) => (
                          <Radio key={category} value={category}>
                            {category}
                          </Radio>
                        ))}
                      </SimpleGrid>
                    </RadioGroup>
                  )}
                </FormControl>

                {/* Positions */}
                <FormControl
                  isInvalid={Boolean(!!errors.position && touched.position)}
                >
                  {/* TODO VERSUS: ajouter le versus ici */}
                  <Checkbox
                    checked={isFilterOnPosition}
                    onChange={(e) => {
                      const isChecked = e.target.checked;
                      setIsFilterOnPosition(isChecked);
                      if (!isChecked) {
                        setFieldValue('position', undefined);
                      } else {
                        setFieldValue('position', {
                          table: 6,
                          seat: undefined,
                        });
                      }
                    }}
                  >
                    <FormLabel m={0}>Positions et types de tables</FormLabel>
                  </Checkbox>
                  {isFilterOnPosition && (
                    <Flex
                      mt={2}
                      ml={2}
                      alignItems={'center'}
                      justifyContent={'space-between'}
                      width={'100%'}
                    >
                      <Box>
                        <RadioGroup
                          colorScheme="orange"
                          value={
                            values.position
                              ? `${values.position.table}`
                              : undefined
                          }
                          onChange={(val) => {
                            const seatExistsInNewTable = values.position?.seat
                              ? getValidSeatsForTableType(+val).includes(
                                  values.position.seat as Seat,
                                )
                              : false;
                            setFieldValue(`position`, {
                              table: +val,
                              seat: seatExistsInNewTable
                                ? values.position?.seat
                                : undefined,
                            });
                          }}
                          mb={2}
                        >
                          <SimpleGrid columns={{ base: 3, md: 6 }} spacingX={3}>
                            <Radio value="10">Full ring</Radio>
                            {[8, 6, 4, 3].map((nbSeat) => (
                              <Radio key={nbSeat} value={`${nbSeat}`}>
                                {`${nbSeat}max`}
                              </Radio>
                            ))}
                            <Radio value="2">Heads-up</Radio>
                          </SimpleGrid>
                        </RadioGroup>

                        <RadioGroup
                          colorScheme="orange"
                          value={values.position?.seat ?? 'inconnu'}
                          onChange={(val) =>
                            setFieldValue(`position.seat`, val)
                          }
                        >
                          <SimpleGrid columns={{ base: 3, md: 6 }} spacingX={3}>
                            {getValidSeatsForTableType(
                              values.position?.table,
                            ).map((seat) => (
                              <Radio key={seat} value={seat}>
                                {seatNames[seat]}
                              </Radio>
                            ))}
                          </SimpleGrid>
                        </RadioGroup>
                      </Box>
                    </Flex>
                  )}
                </FormControl>

                {/* Stacks */}

                <Flex alignItems={'center'}>
                  <Checkbox
                    checked={isFilterOnStack}
                    onChange={(e) => {
                      const isChecked = e.target.checked;
                      setIsFilterOnStack(isChecked);
                      if (!isChecked) {
                        setFieldValue('stack', undefined);
                      }
                    }}
                  >
                    <FormLabel m={0} mr={2}>
                      Stack
                    </FormLabel>
                  </Checkbox>
                  {isFilterOnStack && (
                    <BPCInput
                      oneLiner
                      fieldName="stack"
                      type="number"
                      suffix="bb"
                    />
                  )}
                </Flex>
              </VStack>
            </form>
          );
        }}
      </Formik>
    </Box>
  );
}
