import { Combo } from '@holdem-poker-tools/hand-matrix';
import {
  ALL_SEATS,
  ComboType,
  PokerRange,
  RangeType,
  Seat,
} from './ranges.type';
import {
  NB_HAND_FOR_COMBO,
  TOTAL_NB_HAND,
  rangeTypeColors,
  seatNames,
} from './ranges.constants';
import { formatPercentage } from 'common/string.helper';

export function findRangeTypeForCombo(
  combo: Combo,
  rangeDetails: PokerRange['details'],
): RangeType | undefined {
  const comboDetail = rangeDetails[combo];
  if (!comboDetail) {
    return undefined;
  }
  const rangeTypeListForCombo = Object.keys(comboDetail) as RangeType[];
  if (!rangeTypeListForCombo.length) {
    return undefined;
  }
  return rangeTypeListForCombo[0];
}

export function findRangeTypeColorForCombo(
  combo: Combo,
  rangeDetails: PokerRange['details'],
) {
  const rangeTypeForCombo = findRangeTypeForCombo(combo, rangeDetails);
  if (rangeTypeForCombo) {
    return rangeTypeColors[rangeTypeForCombo];
  }
  return undefined;
}

export function getComboType(combo: Combo): ComboType {
  if (combo.length === 2) {
    return 'pocketPair';
  }
  if (combo.length === 3) {
    const suit = combo[2];
    if (suit === 'o') return 'offsuit';
    if (suit === 's') return 'suited';
  }
  throw new Error(`Combo inconnu: ${combo}`);
}

export function computeTotalCombosForRange(
  rangeDetails: PokerRange['details'],
) {
  const totalCombo: Partial<Record<RangeType, number>> & { total: number } = {
    total: 0,
  };
  for (let [combo, rangeTypes] of Object.entries(rangeDetails)) {
    const comboType = getComboType(combo as Combo);
    const nbHandForCombo = NB_HAND_FOR_COMBO[comboType];
    const rangeTypeNames = Object.keys(rangeTypes || {}) as RangeType[];
    for (let rangeType of rangeTypeNames) {
      totalCombo[rangeType] = (totalCombo[rangeType] ?? 0) + nbHandForCombo;
    }
    if (rangeTypeNames.length) totalCombo.total += nbHandForCombo;
  }
  return totalCombo;
}

export function computeRangePercentageForNbCombos(
  nbCombos: number | undefined,
) {
  if (!nbCombos) {
    return undefined;
  }
  return formatPercentage(nbCombos / TOTAL_NB_HAND, 1);
}

export function formatRangeTableLabel(table: number | undefined) {
  if (!table) {
    return '';
  }
  if (table === 10) {
    return 'Full ring';
  }
  if (table === 2) {
    return 'Heads-up';
  }
  return `${table}max`;
}

// TODO: changer le modèle en base pour n'avoir que les seats et la table. Ensuite caclculer à la volée les infos pour le front
export function formatRangeSeatsLabel(seats: Seat[]) {
  return seats.map((seat) => seatNames[seat]).join('/');
}

function formatRangePositionLabel(positions: PokerRange['positions']) {
  if (!positions.length) {
    return undefined;
  }
  return positions
    .map(
      ({ table, seats, versus }) =>
        `${formatRangeSeatsLabel(seats)}${
          versus?.length ? ` vs ${formatRangeSeatsLabel(versus)}` : ''
        }${table ? `-${formatRangeTableLabel(table)}` : ''}`,
    )
    .join(', ');
}

export function formatRangeStackLabel(stack: PokerRange['stack']) {
  return stack
    ? `${stack.min}${stack.max ? `-${stack.max}bb` : 'bb+'}`
    : undefined;
}

export function formatRangeDescription(range: PokerRange) {
  const positionLabel = formatRangePositionLabel(range.positions);
  const stackLabel = formatRangeStackLabel(range.stack);

  return [positionLabel, stackLabel].join(' ');
}

export function generateRangeToDuplicate(
  range: PokerRange,
): Omit<PokerRange, 'id'> {
  const { id: _id, name: _name, ...restRangeToCopy } = range;

  return {
    ...restRangeToCopy,
    name: undefined,
  };
}

export function getValidSeatsForTableType(
  tableSize: number | undefined,
): Seat[] {
  return tableSize
    ? tableSize === 2
      ? ['bigBlind', 'button']
      : tableSize === 3
      ? ['smallBlind', 'bigBlind', 'button']
      : tableSize === 4
      ? ['smallBlind', 'bigBlind', 'cutoff', 'button']
      : tableSize === 6
      ? ['smallBlind', 'bigBlind', 'utg', 'highJack', 'cutoff', 'button']
      : tableSize === 8
      ? [
          'smallBlind',
          'bigBlind',
          'utg',
          'utg1',
          'lowJack',
          'highJack',
          'cutoff',
          'button',
        ]
      : [...ALL_SEATS]
    : [...ALL_SEATS];
}
