import { ComboType, RangeType, Seat } from './ranges.type';

export const seatNames: Record<Seat, string> = {
  smallBlind: 'Small Blind',
  bigBlind: 'Big blind',
  utg: 'UTG',
  utg1: 'UTG+1',
  utg2: 'UTG+2',
  utg3: 'UTG+3',
  lowJack: 'LowJack',
  highJack: 'HighJack',
  cutoff: 'Cutoff',
  button: 'Bouton',
};

export const rangeTypeNames: Record<RangeType, string> = {
  // Version calamusa
  openFold: 'Open / Fold',
  openCall: 'Open / Call',
  open4bet: 'Open / 4bet',
  open4betAllIn: 'Open / 4bet all-in',
  allIn: 'All-in directement',
  // Version Flavien guenant
  open: 'Open',
  openLoose: 'Open loosifié',
  openTight: 'Open tightifié',
  // Ranges de flat
  flat: 'Flat',
  threeBet: '3bet (value)',
  threeBetLight: '3bet (light)',
  //Ranges de défense
  defenseSolide: 'Défense tout le temps',
  defenseMoyenne: 'Défense moyenne',
  defenseLight: 'Défense light',
};
//https://color-hex.org/color-palettes/187
export const rangeTypeColors: Record<RangeType, string> = {
  // Version calamusa
  openFold: '#abc4e7', // bleu (https://www.color-hex.com/color-palette/99176)
  openCall: '#a3ff00', // vert clair
  open4bet: '#fff400', // jaune
  open4betAllIn: '#ffa700', // orange
  allIn: '#ff0000', // rouge
  // Version Flavien guenant
  open: '#a3ff00', // vert clair
  openLoose: '#abc4e7', // bleu
  openTight: '#2cba00', // vert foncé
  //Ranges de flat
  flat: '#a3ff00', // vert clair
  threeBet: '#ffa700', // orange
  threeBetLight: '#fff400', // jaune
  //Ranges de défense
  defenseSolide: '#a3ff00', // vert clair
  defenseMoyenne: '#2cba00', // vert foncé
  defenseLight: '#abc4e7', // bleu
};

export const TOTAL_NB_HAND = (52 * 51) / 2;

export const NB_HAND_FOR_COMBO: Record<ComboType, number> = {
  offsuit: 12,
  pocketPair: 6,
  suited: 4,
};
